'use strict'

angular.module('cb.ipad', ['ui-leaflet'])
	.controller('IpadSalesFitterDashboardController', ["$q", "$scope", "$rootScope", "$timeout", "$location", "$routeParams", "NotificationService", "ReportService", "StaffService", "IpadService", "LeadProfileService", "PickListService", "MaintenanceService", "LeadProviderService", "SearchService", "AppointmentService", "EnquiryModalService", "$modal", "SaleOrderService", function ($q, $scope, $rootScope, $timeout, $location, $routeParams, NotificationService, ReportService, StaffService, IpadService, LeadProfileService, PickListService, MaintenanceService, LeadProviderService, SearchService, AppointmentService, EnquiryModalService, $modal, SaleOrderService) {
		$scope.contractMarkers = {};
		$scope.contractConsole = { Filter: "All", Fitter: "" };
		$scope.contractConsoleDrpStart = moment().subtract(1, 'months').format();
		$scope.contractConsoleDrpEnd = moment().format();
		$scope.contractConsoleDrp_options = {
			timePicker: false,
			opens: 'left',
			startDate: $scope.contractConsoleDrpStart,
			endDate: $scope.contractConsoleDrpEnd,
			format: 'DD/MM/YYYY',
			showDropdowns: true
		};
		
		$scope.init = function () {
			$scope.IsOnline = $rootScope.onLineFlag;

			$scope.salesMapFilterOptions = [
				'All',
				'NewLeads',
				'NotAppointedLeads',
				'AppointedLeads',
				'QuotedLeads',
				'Sold'
			];

			$scope.fitterMapFilterOptions = [
				'All',
				'SoldSurvey',
				'SurveyBooked',
				'InstallBooked',
				'Remakes',
				'ServiceCalls',
				'CharagableServiceCalls',
				'MaintenanceVisit',
				'PhoneCall'
			];

			$scope.StaffList = [];
			$scope.FittersList = [];
			$scope.Recipients = [];
			$scope.NonrecipientList = [];
			$scope.getStaff();

			$scope.IpadAppointments = [];
			$scope.sHolidayRequests = [];
			$scope.recipientList = [];
			$scope.selectedUsers = [];
			$scope.areaManager;
			$scope.Holidays;
			$scope.myFilter = "All";
			$scope.selectedStaff;
			$scope.showFilter = false;
			$scope.showingOtherEvents = false;
			$scope.viewAllPermission = false;
			$scope.contractsPermission = false;
			$scope.salesPermission = false;
			$scope.accountsPermission = false;

			// Anon best in company.
			$scope.DisplayCompanyBest = false;
			$scope.GetAnonBestInCompany();

			// Calendar.
			$scope.CalendarFilter = "";
			$scope.CalStart = moment().subtract(1, 'month');
			$scope.CalEnd = moment().add(2, 'month');
			getAppointments();

			// Dates.
			$scope.drp_start = moment().subtract(2, 'months');
			$scope.drp_end = moment();

			$scope.drp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.drp_start,
				endDate: $scope.drp_end,
				format: 'DD/MM/YYYY',
				showDropdowns: true
			};

			// Memo
			$scope.Memo = {
				Parent: '',
				Message: "",
				Subject: "",
				Recipients: []
			};

			// Customer search.
			$scope.Search = {};
			$scope.searchPageNumber = 1;
			$scope.searchPagination = {};
			$scope.searchAttempts = 0;

			// Dashboard map.
			$scope.MapPageNum = 0;
			$scope.lastMarkerFocused = 0;
			$scope.map = {};
			$scope.markers = {};
			$scope.initMap();

			// CONTRACT CONSOLE.
			$scope.contractConsole = { Filter: "All", Fitters: [] };
			$scope.contractConsoleDrpStart = moment().subtract(1, 'months').format();
			$scope.contractConsoleDrpEnd = moment().format();
			$scope.contractConsoleDrp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.contractConsoleDrpStart,
				endDate: $scope.contractConsoleDrpEnd,
				format: 'DD/MM/YYYY',
				showDropdowns: true
			};

			// QUOTE CONSOLE.
			$scope.QuoteDetails = [];

			// SURVEY CONSOLE.
			$scope.RefNum = null;
			$scope.surveyLines = [];
			$scope.surveySummary = [];
			$scope.showSurveyLines = false;
			$scope.openPopups = {};

			// INSTALLATION CONSOLE.		
			$scope.initialLoad = true;

			// Select first tab.
			$scope.dashboardTab = 0;

			$scope.disabledDropdown = false;
			$scope.limitTo = 10;
			$scope.getPickLists();				
		};

		var stopWatchingUser = $scope.$watch('$rootScope.User', function (newUser) {
			//alert(JSON.stringify($rootScope.User));

			if ($rootScope.User == undefined) {
				$rootScope.User = {};
				//alert(JSON.stringify($rootScope.User));
				return;
			}

			if ($rootScope.User && $rootScope.User.Id) {
				$scope.IsUserSalesman = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person' || r.Name == 'Sales Manager').length > 0 ? true : false;
				$scope.IsUserFitter = $rootScope.User.Roles.filter(r => r.Name == 'Fitter').length > 0 ? true : false;
				$scope.IsUserSalesmanFitter = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person/Fitter').length > 0 ? true : false;
				$scope.IsUserContract = $rootScope.User.Roles.filter(r => r.Name == 'Contracts').length > 0 ? true : false;
				$scope.active = $scope.IsUserSalesmanFitter ? 0 : $scope.IsUserSalesman ? 0 : 6;

				if ($scope.IsUserSalesmanFitter)
					$scope.fitterMapFilterOptions.shift();

				if ($routeParams.id && $routeParams.type) {
					setTimeout(() => {
						if ($routeParams.type == "survey") {
							$scope.ChangeDashboardTab(6);
							$scope.RefNum = parseInt($routeParams.id);
							$scope.SubmitSurveyRef($scope.RefNum);
						}
						else if ($routeParams.type == "install") {
							$scope.ChangeDashboardTab(7);
							$scope.RefNum = parseInt($routeParams.id);
							$scope.SubmitInstallRef($scope.RefNum);
						}
					}, 1);
				}
			}

			if ($rootScope.User != {}) {
				$scope.recieveDashboardData();
				stopWatchingUser();
			}

		});

		$scope.$watchCollection('[drp_start, drp_end]', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
			$scope.displayEndDate = moment($scope.drp_end).local().format('DD/MM/YYYY');
			//if ($scope.gettingData)
				$scope.recieveDashboardData();
		});

		$scope.$on('UserPopulated', function () {
			$scope.IsUserSalesman = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person' || r.Name == 'Sales Manager').length > 0 ? true : false;
			$scope.IsUserFitter = $rootScope.User.Roles.filter(r => r.Name == 'Fitter').length > 0 ? true : false;
			$scope.IsUserSalesmanFitter = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person/Fitter').length > 0 ? true : false;
			$scope.IsUserContract = $rootScope.User.Roles.filter(r => r.Name == 'Contracts').length > 0 ? true : false;
			$scope.active = $scope.IsUserSalesmanFitter ? 0 : $scope.IsUserSalesman ? 0 : 6;

			if ($scope.IsUserSalesmanFitter)
				$scope.fitterMapFilterOptions.shift();

			$scope.recieveDashboardData();

		});

		$scope.toggleCompanyBest = function () {
			$scope.DisplayCompanyBest = !$scope.DisplayCompanyBest;
		}

		$scope.getPickLists = function () {
			if ($rootScope.onLineFlag) {
				$scope.CollectionPromise = PickListService.getPickListByPickListName(['Title', 'Contact', 'AppointmentTime', 'ddmBraidColour',
					'ddmFabric',
					'ddmHandleColour',
					'ddmEndCapColour',
					'ddmLayout',
					'ddmOperation',
					'ddmParking',
					'ddmPosition',
					'ddmProduct',
					'ddmProductType',
					'ddmRailColour',
					'ddmRemoteColour',
					'ddmShape',
					'ddmSupplier',
					'ddmWallType',
					'ddmOperation',
					'ddmContactType',
					'ddmWireColour',
					'ddmProduct',
					'ddmProductType',
					'ddmQualityControl',
					'BlindType',
					'BlindUse',
					'ContactType',
					'ddmContractTerm',
					'altContactType',
					'ddmRidgeHeight',
					'ddmSiteAccess',
					'ddmAccessEquipment',
					'ContractType',
					'InitialContactMethod',
					'System',
					'ddmDamage',
					'ddmPaymentTypes'
				]);
				$scope.CollectionPromise.then(function (output) {
					$scope.picklist = output.data.PickListEntries;
				}, function (error) { });
				//customer search lead status
				$scope.GetAllStatusPromise = MaintenanceService.getAllStatuses();
				$scope.GetAllStatusPromise.then(function (output) {
					$scope.LeadStatusData = output.data.StatusList;
				});
				$scope.GetAllStatusGroupPromise = MaintenanceService.getAllStatuseGroups();
				$scope.GetAllStatusGroupPromise.then(function (output) {
					$scope.LeadStatusGroupData = output.data.StatusGroupList;
				}, function (error) {
					NotificationService.alert('Lead Status Group Error', 'There was an error loading some information for this page, please try refreshing.', 'alert', {});
				});
				$scope.GetLeadProviderPromise = LeadProviderService.getAllLeadProviders();
				$scope.GetLeadProviderPromise.then(function (output) {
					$scope.LeadProviderData = output.data.LeadProviders;
				}, function (error) {
					NotificationService.alert('Lead Provider Error', 'There was an error loading some information for this page, please try refreshing.', 'alert', {});
				});


			}
			else {
				//TO BE IMPLEMENTED -- OFFLINE SYNC NEEDS TO FETCH PICKLISTS AND DECLARE THESE $scope ITEMS.
				//$scope.picklist
				const picklist = IpadService.getPicklist();
				picklist.then(function (data) {
					$scope.picklist = data;
					//console.log(data);
				})
				//$scope.LeadStatusData getStatusLists
				const LeadStatusData = IpadService.getStatusLists()
				LeadStatusData.then(function (data) {
					$scope.LeadStatusData = data;
					console.log(data);
				})
				//$scope.LeadStatusGroupData
				const LeadStatusGroupData = IpadService.getStatusGroups()
				LeadStatusGroupData.then(function (data) {
					$scope.LeadStatusGroupData = data;
					//console.log(data);
				})
				//$scope.LeadProviderData
				const LeadProviderData = IpadService.getLeadProviders()
				LeadProviderData.then(function (data) {
					$scope.LeadProviderData = data;
					//console.log(data);
				})

				const LeadProfile = IpadService.getLeadProfiles();
				LeadProfile.then(function (data) {
					$scope.LeadProfiles = data;
					//console.log(data);
				})
			}
		}

		//Anon best in company
		$scope.GetAnonBestInCompany = function () {
			$scope.getAnonPromise = ReportService.GetAnonBestInCompany();
			$scope.getAnonPromise.then(function (output) {
				$scope.AnonBestSalesVal = output.data.TopTotal;
				$scope.UserSalesVal = output.data.UserTotal;
			});
		}

		//dashboard tab
		$scope.goToLead = function (ref) {
			window.open('/#/lead-details/' + ref, '_blank');
		}

		$scope.goToSale = function (ref) {
			window.open('/#/sales-order/' + ref, '_blank');
		}

		$scope.recieveDashboardData = function () {
			//alert(100);
			if (!$scope.gettingData) {
				//alert(0);
				if (JSON.stringify($rootScope.User) !== JSON.stringify({})) {
					$scope.gettingData = true;
					//alert(1);
					if ($rootScope.onLineFlag) {
						console.debug($rootScope);
						var reportsData = {
							SalesmenId: $rootScope.User.Id,
							StartDate: $scope.drp_start,
							EndDate: $scope.drp_end
						};
						//alert(2);
						$scope.ReportDataPromise = $scope.IsUserSalesman ? ReportService.GetSalesmanReports(reportsData) : ReportService.GetFitterReports(reportsData);
						$scope.ReportDataPromise.then(function (output) {
							$scope.gettingData = false;
							$scope.ReportData = output.data;

							if (!$scope.IsUserSalesman && $scope.initialLoad)
								if ($scope.ReportData['SoldSurvey'].filter(x => x.NoInitialContactDate).length > 0) {
									$scope.initialLoad = false;
									CreateJobAlerts($scope.ReportData['SoldSurvey'].filter(x => x.NoInitialContactDate));
								}

							if ($scope.IsUserSalesmanFitter) {
								ReportService.GetSalesmanReports(reportsData).then(function (output) {
									Object.keys(output.data).forEach(function (key) {
										$scope.ReportData[key] = output.data[key];
									})
								})
							} else
								$scope.markerSetup();
						});
					}
					else {
						const reportsData = $scope.IsUserSalesman ? IpadService.getSalesmanReport(localStorage.getItem('userId')) : IpadService.getFitterReport(localStorage.getItem('userId'));
						reportsData.then(function (data) {
							$scope.ReportData = data;
						})
					}
				}
			}
		}

		function CreateJobAlerts(jobs) {
			jobs.forEach(function (job) {
				$scope.creatingAlertPromise = EnquiryModalService.createAlert({
					Alert: {
						Message: "Job #" + job.Id + " has now passed three days without contact",
						DealtWith: false,
						IsDeleted: false,
						ObjectId: job.Id,
						UserId: $rootScope.User.Id,
						SentByUser: $rootScope.User.Id
					}
				});
				$scope.creatingAlertPromise.then(success => {
					//DO NOTHING
				}, err => {
					NotificationService.alert('Alert Failed', 'Failed to create the alert.', 'error', {});
				});
			})
			$scope.$emit('StartUpdateKPIs');
		}

		$scope.changeActive = function (index) {
			$scope.active = index;
		};

		$scope.ChangeDashboardTab = function (index) {
			$scope.dashboardTab = index;
		};

		//dashboard map
		$scope.mapToProfile = function (ref) {
			//if fitter, go to sale order, else go to lead profile. 
			if ($rootScope.User.Roles.filter(r => r.Name == 'Fitter').length > 0)
				window.open('/#/sales-order/' + ref, '_blank');
			else
				window.open('/#/lead-profile/' + ref, '_blank');
		}

		$scope.selectMarker = function (Id) {
			if (Id == 0)
				NotificationService.alert('Error', 'There was an error requesting this marker.', 'error', {});
			else
				$scope.markers[Id].focus = !$scope.markers[Id].focus;

			if ($scope.lastMarkerFocused != Id && $scope.lastMarkerFocused != 0)
				$scope.markers[$scope.lastMarkerFocused].focus = false;

			$scope.lastMarkerFocused = Id;

			angular.extend($scope, {
				center: {
					lat: $scope.markers[Id].lat,
					lng: $scope.markers[Id].lng,
					zoom: 10
				},
				defaults: {
					tileLayer: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
					scrollWheelZoom: false,
					maxZoom: 20
				}
			});
		};

		$scope.initMap = function () {
			angular.extend($scope, {
				center: {
					lat: 51.505,
					lng: -0.09,
					zoom: 10
				},
				defaults: {
					tileLayer: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
					scrollWheelZoom: false,
					maxZoom: 20
				}
			});
		};

		$scope.getLatLng = function () {
			var Ids = [];
			for (let i = 0; i < $scope.AllLeads.length; i++) {
				if ($scope.AllLeads[i] != null)
					Ids.push($scope.AllLeads[i].Id);
			}
			var Data = {
				ExternalRef: Ids
			};
			if (Data.ExternalRef.length > 0) {
				$scope.getLatLngPromise = LeadProfileService.getLatLng(Data);
				$scope.getLatLngPromise.then(function (output) {
					$scope.Addresses = output.data.Addresses;
					$scope.CreateMarkers();
				});
			}
		}

		$scope.markerSetup = function () {
			//group all appointments/assigned leads into one.
			$scope.AllLeads = [];
			if ($scope.IsUserSalesman || $scope.IsUserSalesmanFitter) {
				if ($scope.ReportData.NewLeads.length != 0)
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.NewLeads);
				if ($scope.ReportData.NotAppointedLeads.length != 0)
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.NotAppointedLeads);
				if ($scope.ReportData.AppointedLeads.length != 0)
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.AppointedLeads);
				if ($scope.ReportData.QuotedLeads.length != 0)
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.QuotedLeads);
				if ($scope.ReportData.Sold.length != 0)
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.Sold);
			}
			if (!$scope.IsUserSalesman) {
				if ($scope.ReportData.SoldSurvey.length != 0)
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.SoldSurvey);
				if ($scope.ReportData.SurveyBooked.length != 0)
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.SurveyBooked);
				if ($scope.ReportData.InstallBooked.length != 0)
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.InstallBooked);
				if ($scope.ReportData.Remakes.length != 0)
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.Remakes);
				if ($scope.ReportData.ServiceCalls.length != 0)
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.ServiceCalls);
				if ($scope.ReportData.CharagableServiceCalls.length != 0)
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.CharagableServiceCalls);
				if ($scope.ReportData.MaintenanceVisit.length != 0)
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.MaintenanceVisit);
				if ($scope.ReportData.PhoneCall.length != 0)
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData.PhoneCall);
			}

			if ($rootScope.onLineFlag == false) {//offline data is going to need to calculate the lat lng when syncing
				//TO BE IMPLEMENTED -- SYNC SHOULD REQUEST BACKEND METHOD TO GET LAT LNG VALUES WHEN SYNCING.
			}
			else {
				MapPaginatedTableValues();
				$scope.getLatLng();// grab addresses
			}
		}

		$scope.MapFilter = function () {
			$scope.lastMarkerFocused = 0;
			$scope.markers = {};
			$scope.AllLeads = [];
			if ($scope.map.filter != "" && $scope.map.filter != null && $scope.map.filter != undefined && $scope.map.filter != "All") {
				$scope.AllLeads = $scope.ReportData[$scope.map.filter];
			}
			else {
				Object.keys($scope.ReportData).forEach(function (key) {
					$scope.AllLeads = $scope.AllLeads.concat($scope.ReportData[key]);
				})
			}
			MapPaginatedTableValues();
			$scope.getLatLng(); // grab addresses
		}

		$scope.CreateMarkers = function () {
			for (let i = 0; i < $scope.AllLeads.length; i++) {
				//create marker.
				var id = $scope.AllLeads[i].Id;
				var address = $scope.Addresses.find(ad => ad.Postcode === $scope.AllLeads[i].Postcode);
				if (address != null && address.Lat != null && address.Lng != null) {
					$scope.markers[id] = {
						lat: parseFloat(address.Lat),
						lng: parseFloat(address.Lng),
						focus: false,
						message: $scope.IsUserSalesman ? "<dt>Name: " + $scope.AllLeads[i].LeadName + "</dt>"
							+ "<dt>Address : " + address.PrimaryStreet + "</dt>"
							+ "<dt>Town/City : " + address.Town + "</dt>"
							+ "<dt>County : " + address.County + "</dt>"
							+ "<dt>Postcode: " + address.Postcode + "</dt>"
							+ "<dt>Phone Number: " + ($scope.AllLeads[i].HomePhone || "") + "</dt>"
							+ "<dt>Appointment: " + $scope.AllLeads[i].AppointmentString + "</dt>"
							+ "<dt>Product Details: " + $scope.AllLeads[i].ProductDetails + "</dt>"
							+ "<dt>Order: <a href='/#/lead-details/" + id + "'>" + id + "</a>" + "</dt>" : "<dt>Name: " + $scope.AllLeads[i].LeadName + "</dt>"
							+ "<dt>Address : " + address.PrimaryStreet + "</dt>"
							+ "<dt>Town/City : " + address.Town + "</dt>"
							+ "<dt>County : " + address.County + "</dt>"
							+ "<dt>Postcode: " + address.Postcode + "</dt>"
							+ "<dt>Phone Number: " + ($scope.AllLeads[i].HomePhone || "") + "</dt>"
							+ "<dt>Job: " + $scope.AllLeads[i].Job + "</dt>"
							+ "<dt>Job Booked: " + ($scope.AllLeads[i].AppDate || "") + "</dt>"
							+ "<dt>Order: <a href='#/sales-order/" + id + "' target='_blank'>" + id + "</a>" + "</dt>",
						icon: {
							iconSize: [25, 41],
							iconAnchor: [12, 41],
							popupAnchor: [1, -34],
						}
					};
					if ($scope.IsUserSalesman || $scope.IsUserSalesmanFitter) {
						switch ($scope.AllLeads[i].SaleStatus) {
							case "New Lead":
								$scope.markers[id].icon.iconUrl = "assets/img/enquiry-salesorder-marker.png";
								break;
							case "Not Appointed":
								$scope.markers[id].icon.iconUrl = "assets/img/spare-marker2.png";
								break;
							case "Appointed":
								$scope.markers[id].icon.iconUrl = "assets/img/holiday-marker.png";
								break;
							case "Quoted: Hot":
								$scope.markers[id].icon.iconUrl = "assets/img/spare-marker1.png";
								break;
							case "Sold":
								$scope.markers[id].icon.iconUrl = "assets/img/survey-marker.png";
								break;
							default:
								$scope.markers[id].icon.iconUrl = "assets/img/default-service-marker.png";
								break;
						}
					}
					if (!$scope.IsUserSalesman) {
						switch ($scope.AllLeads[i].Job) {
							case "Sold (To Survey)":
								if ($scope.AllLeads[i].Fitter == undefined || $scope.AllLeads[i].Fitter == "")
									$scope.markers[id].icon.iconUrl = "assets/img/spare-marker1.png";
								else
									$scope.markers[id].icon.iconUrl = "assets/img/enquiry-salesorder-marker.png";
								break;
							case "Survey":
								$scope.markers[id].icon.iconUrl = "assets/img/survey-marker.png";
								break;
							case "Installation":
								$scope.markers[id].icon.iconUrl = "assets/img/installation-marker.png";
								break;
							case "Remakes":
								$scope.markers[id].icon.iconUrl = "assets/img/remake-marker.png";
								break;
							case "Service Call":
								$scope.markers[id].icon.iconUrl = "assets/img/Service-marker.png";
								break;
							case "Charageable Service Call":
								$scope.markers[id].icon.iconUrl = "assets/img/chargeable-marker.png";
								break;
							case "Maintenance Visit":
								$scope.markers[id].icon.iconUrl = "assets/img/maintenance-marker.png";
								break;
							case "Customer Phone Call Request":
								$scope.markers[id].icon.iconUrl = "assets/img/courtesy-marker.png";
								break;
						}
					}
					$scope.AllLeads[i].AddressLookupFailed = false;
				}
				else { //postcode lookup failed as this address has null values for lat/lng. Disable highlight button.
					$scope.AllLeads[i].AddressLookupFailed = true;
				}
			}
		}

		function MapPaginatedTableValues() {
			$scope.mapDisplayedItems = [];
			$scope.mapPageNum = 0;
			if ($scope.AllLeads.length < 6) {
				for (let i = 0; i < $scope.AllLeads.length; i++) {
					$scope.mapDisplayedItems.push($scope.AllLeads[i])
				}
			}
			else {
				for (let i = 0; i < 6; i++) {
					$scope.mapDisplayedItems.push($scope.AllLeads[i])
				}
			}

		}

		$scope.MapIncrementPage = function () {
			if ($scope.mapPageNum + 1 < $scope.AllLeads.length / 6) {
				$scope.mapPageNum++;
				var remainder = $scope.AllLeads.length % 6;
				$scope.mapDisplayedItems = [];
				if ($scope.AllLeads.length < 6) {
					for (let i = 0; i < $scope.AllLeads.length; i++) {
						$scope.mapDisplayedItems.push($scope.AllLeads[i])
					}
				}
				else if (remainder != 0 && $scope.AllLeads.length < ($scope.mapPageNum + 1) * 6) { //this means this is the final page which has less than 6 results.
					for (let i = 0; i < remainder; i++) {
						var leadId = ($scope.mapPageNum * 6) + i;
						$scope.mapDisplayedItems.push($scope.AllLeads[leadId])
					}
				}
				else {
					for (let i = $scope.mapPageNum * 6; i < ($scope.mapPageNum * 6) + 6; i++) {
						$scope.mapDisplayedItems.push($scope.AllLeads[i])
					}
				}
			}
		}

		$scope.MapDecrementPage = function () {
			if ($scope.mapPageNum >= 0) {
				$scope.mapPageNum--;
				$scope.mapDisplayedItems = [];
				for (let i = $scope.mapPageNum * 6; i < ($scope.mapPageNum * 6) + 6; i++) {
					$scope.mapDisplayedItems.push($scope.AllLeads[i])
				}
			}
		}

		$scope.formatFilterOption = function (string) {
			return string.replace(/([A-Z])/g, ' $1').trim();
		}

		//calendar
		function getAppointments() { // need to add local cached holidays / appointments

			// Check if online then
			// if online pull from api
			// else pull from local cache
			if ($rootScope.onLineFlag) {
				var data = {
					Start: $scope.CalStart,
					End: $scope.CalEnd
				}
				$scope.GettingAppointmentsPromise = AppointmentService.getAppointmentsForCurrentUser(data);
				$scope.GettingAppointmentsPromise.then(function (output) {
					$scope.IpadAppointments = [];
					angular.forEach(output.data.Appointments, function (value, key) {
						if (!value.IsDeleted && value.EventType == 'Appointment') {
							var eventInfo = SetEventColor(value)
							var appointment = {
								id: value.Id,
								title: value.Title + " " + eventInfo.ApprovalStatus,
								start: moment(value.Start).unix(),
								end: moment(value.End).unix(),
								colour: eventInfo.Color,
								url: $scope.mkiUrl(value.ObjType, value.ExternalRef),
								EventType: value.EventType,
								MaintenanceId: value.MaintenanceId
							};
							this.push(appointment);
						}
					}, $scope.IpadAppointments);
				}, function (error) {
					NotificationService.alert('Error Retrieving Appointments', 'An unexpected error occurred when trying to retrieve appointments.', 'error', {});
				});
			} else {
				$scope.GettingAppointmentsPromise = IpadService.getAppointments();
				$scope.GettingAppointmentsPromise.then(function (output) {
					$scope.IpadAppointments = [];
					angular.forEach(output, function (value, key) {
						if (!value.IsDeleted && value.EventType == 'Appointment') {
							var eventInfo = SetEventColor(value)
							var appointment = {
								id: value.Id,
								title: value.Title + " " + eventInfo.ApprovalStatus,
								start: moment(value.Start).unix(),
								end: moment(value.End).unix(),
								colour: eventInfo.Color,
								url: $scope.mkiUrl(value.ObjType, value.ExternalRef),
								EventType: value.EventType,
								MaintenanceId: value.MaintenanceId
							};
							this.push(appointment);
						}
					}, $scope.IpadAppointments);
				}, function (error) {
					NotificationService.alert('Error Retrieving Appointments', 'An unexpected error occurred when trying to retrieve appointments.', 'error', {});
				});
			}
		}

		function SetEventColor(value) {
			var ApprovalStatus;
			var Color;
			switch (value.EventType) {
				case 'Holiday':
					switch (value.Approved) {
						case 0:
							ApprovalStatus = "- Pending";
							Color = '#82c4e6';
							break;
						case 1:
							ApprovalStatus = "- Rejected";
							Color = '#e74c3c';
							break;
						case 2:
							ApprovalStatus = "- Approved";
							Color = '#3168cc';
							break;
						default:
							break;
					}
					break;
				case 'PublicHoliday':
					Color = '#9299a5';
				case 'Appointment':
					switch (value.ObjType) {
						case 'Enquiry':
						case 'enquiry':
						case 'SalesOrder':
							Color = '#d598a6';
							break;
						case 'Pre-Survey':
							Color = '#20A4F3';
							break;
						case 'Survey':
							Color = '#2C5F2D';
							break;
						case 'Installation':
							Color = '#9043f7';
							break;
						case 'Service':
							Color = '#90263d'
							break;
						case 'Remake':
							Color = '#ba2642'
							break;
						case 'Courtesy':
							Color = '#ffd966';
							break;
						case 'Chargeable':
							Color = '#ff9900'
							break;
						case 'Maintenance':
							Color = '#7f6000'
							break;
						default:
							if (value.ObjType.includes('Service')) {
								Color = '#990000'
							}
							break;
					}
					ApprovalStatus = '';
					break;
				default:
					break;
			}

			return {
				ApprovalStatus: ApprovalStatus,
				Color: Color,
			};
		};

		$scope.mkiUrl = function (type, id) {
			if (id == 0)
				return null;
			return '#/lead-details/' + id;
		};

		$scope.$on('EditAppointment', (event, Data) => {
			if (Data.Type == 'Maintenance') {
				var data = {
					MaintenanceId: Data.Id
				};
				var modalInstance = $modal.open({
					animation: true,
					size: 'lg',
					templateUrl: 'views/modal-templates/maintenance.html',
					controller: 'SurveyMaintenanceController',
					resolve: {
						data: function () {
							return data;
						}
					}
				});
			} else {
				var modalInstance = $modal.open({
					animation: true,
					size: 'lg',
					templateUrl: 'views/modal-templates/update-appointment.html',
					controller: 'UpdateAppointmentModalCtrl',
					resolve: {
						data: function () {
							return Data.Id;
						}
					}
				});
			}

			modalInstance.result.then(function () {
				if ($scope.selectedUsers.length)
					$scope.filterAppointments();
				else if ($scope.selectedStaff == 'Everyone')
					$scope.viewAll();
				else
					getAppointments();
			});
		});

		$scope.filterAppointments = function () {
			var filterList = $scope.selectedUsers.map(rec => rec.Id);
			$scope.selectedStaff = $scope.selectedUsers.map(user => user.FirstName + ' ' + user.LastName + ', ').join('').slice(0, - 2);
			$scope.GettingAppointmentsUsers(filterList);
		};

		//memo tab
		$scope.getStaff = function () {
			if ($rootScope.onLineFlag) {
				$scope.GettingStaffListPromise = EnquiryModalService.getStaff();
				$scope.GettingStaffListPromise.then(function (output) {
					$scope.StaffList = output.data.StaffList;
					$scope.FittersList = output.data.StaffList.filter(f => f.Roles.filter(r => r.Name.indexOf("Fitter") != -1).length > 0);
					$scope.NonrecipientList = output.data.StaffList;
				}, function (error) {
					NotificationService.alert('Staff Error', 'Failed to retrieve the list of staff.', 'error', {});
				})
			}
			else {
				//TO BE IMPLEMENTED -- NEED TO CACHE STAFF LIST
				const allstaff = IpadService.getAllStaff();
				allstaff.then(function (data) {
					$scope.StaffList = data;
					$scope.NonrecipientList = data;
				})
			}
		}

		$scope.AddStaff = function (name, index) {
			$scope.Recipients.push(name);
			$scope.NonrecipientList.splice($scope.NonrecipientList.indexOf(name), 1);
		}

		$scope.RemoveStaff = function (name, index) {
			$scope.NonrecipientList.push(name);
			$scope.Recipients.splice($scope.Recipients.indexOf(name), 1);
		}

		$scope.SendMemo = function () {
			if ($rootScope.onLineFlag == true) {
				$scope.Memo.Recipients = $scope.Recipients;//.map(a => a.Id)
				$scope.AddingMemoPromise = EnquiryModalService.addMemo($scope.Memo);
				$scope.AddingMemoPromise.then(function (output) {
					NotificationService.alert('Memo Sent', 'Memo sent successfully.', 'success', {});
					$scope.ClearMemo();
				}, function (error) {
					NotificationService.alert(' Memo Failed', 'Failed to send the Memo' + ".", 'error', {});
				});
			}
			else {
				//TO BE IMPLEMENTED -- NEED TO CACHE MEMO SO IT CAN BE SENT WHEN NETWORK IS RESTORED.
				$scope.Memo.Recipients = $scope.Recipients.map(a => a.Id)
				const memo = IpadService.sendMemo({ MemoDetails: $scope.Memo.Message, ParentMemp: $scope.Memo.Parent, Recipients: $scope.Memo.Recipients, Subject: $scope.Memo.Subject })
				console.log($scope.Memo);
				memo.then(function (out) {
					NotificationService.alert('Memo Saved', 'Memo Saved successfully. and will be sent when online', 'success', {});
				})

			}
		};

		$scope.ClearMemo = function () {
			$scope.Memo = {
				Parent: '',
				Message: "",
				Subject: "",
				Recipients: []
			};
			var recipientsLength = $scope.Recipients.length;
			for (let i = 0; i < recipientsLength; i++) {
				$scope.RemoveStaff($scope.Recipients[0], 1);
			}
		}

		//search for customer tab
		$scope.$watch('searchPageNumber', function (n, o) {
			getSearch();
		});

		$scope.search = function () {
			if ($scope.searchPageNumber == 1)
				getSearch();
			else
				$scope.searchPageNumber = 1;

		};

		function getSearch() {
			if ($scope.searchAttempts > 0) {
				var refs = $scope.Search.Validated.RefNumber;
				if (refs && refs.length) {
					refs.trim();
					if (refs.slice(-1) == ',')
						refs = refs.slice(0, -1);
					$scope.Search.Validated.RefNumber = refs.split(',');
				};

				$scope.Search.Pagination = {
					StartPage: $scope.searchPageNumber - 1,
					PageSize: 15
				};
				if ($rootScope.onLineFlag == true) {

					//REF = 1	0000000001		Search.Validated.RefNumber					0	1
					//SUR = 2	0000000010		Search.Validated.Surname					0	1
					//PHN = 4	0000000100		Search.Validated.Phone						0	1
					//STS = 8	0000001000		Search.Validated.SelectedStatus				0	1 { Not 0 an Id }
					//LEP = 16	0000010000		Search.Validated.SelectedLeadProvider		0	1 { Not 0 an Id }
					//PCD = 32	0000100000		Search.Validated.Postcode					0	1
					//CMP = 64	0001000000		Search.Validated.Company					0	1
					//EML = 128	0010000000		Search.Validated.Email						0	1
					//SGP = 256	0100000000		Search.Validated.SelectedStatusGroup		0 	1 { Live X Pre - Sales Sold Unsold }

					$scope.Search.SearchMode = 0;

					if ($scope.Search.Validated.RefNumber != null && $scope.Search.Validated.RefNumber.length > 0)
						$scope.Search.SearchMode += 1;

					if ($scope.Search.Validated.Surname != null && $scope.Search.Validated.Surname.length > 0)
						$scope.Search.SearchMode += 2;

					if ($scope.Search.Validated.Phone != null && $scope.Search.Validated.Phone.length > 0)
						$scope.Search.SearchMode += 4;

					if ($scope.Search.Validated.SelectedStatus != null && $scope.Search.Validated.SelectedStatus != undefined)
						$scope.Search.SearchMode += 8;

					if ($scope.Search.Validated.SelectedLeadProvider != null && $scope.Search.Validated.SelectedLeadProvider != undefined)
						$scope.Search.SearchMode += 16;

					if ($scope.Search.Validated.Postcode != null && $scope.Search.Validated.Postcode.length > 0)
						$scope.Search.SearchMode += 32;

					if ($scope.Search.Validated.Company != null && $scope.Search.Validated.Company.length > 0)
						$scope.Search.SearchMode += 64;

					if ($scope.Search.Validated.Email != null && $scope.Search.Validated.Email.length > 0)
						$scope.Search.SearchMode += 128;

					if ($scope.Search.Validated.SelectedStatusGroup != null && $scope.Search.Validated.SelectedStatusGroup != undefined)
						$scope.Search.SearchMode += 256;


					$scope.SeachResultPromise = SearchService.searchCustomer($scope.Search);
					$scope.SeachResultPromise.then(function (output) {
						$scope.displaySearchResults = true;
						$scope.searchResults = output.data.SearchResult;
						$scope.searchPagination = output.data.Pagination;
						$scope.numberOfPages = function () {
							return Math.ceil($scope.searchResults.length / $scope.pageSize);
						};
						if ($scope.Search.Validated.RefNumber)
							$scope.Search.Validated.RefNumber = $scope.Search.Validated.RefNumber.join(', ');
					});
				}
				else {
					//TO BE IMPLEMENTED -- THIS NEEDS TO SEARCH THE LOCAL SYNCED ITEMS AND DISPLAY THEM.
					var SeachResultPromise = IpadService.customerSearch($scope.Search);
					SeachResultPromise.then(function (output) {
						$scope.displaySearchResults = true;
						$scope.searchResults = output.SearchResult;
					})
				}
			} else
				$scope.searchAttempts++;
		};

		$scope.reset = function () {
			delete $scope.displaySearchResults;
		};

		$scope.increment = function () {
			$scope.searchPageNumber++;
			getSearch();
		};

		$scope.decrement = function () {
			$scope.searchPageNumber--;
			getSearch();
		};

		//quotation console
		$scope.SubmitQuoteRef = function (refNumber) {
			if (Number.isInteger(refNumber)) {
				if ($rootScope.onLineFlag == true) {
					$scope.QuotePromise = LeadProfileService.getLeadById(refNumber);
					$scope.QuotePromise.then(function (output) {
						$scope.quote = output.data.LeadDetail;
						$scope.quoteRefNumber = refNumber;
						if ($scope.quote.OrderId > 0)
							$scope.DisableQuoteSold = true;
						else
							$scope.DisableQuoteSold = false;

					}, function (error) {
						NotificationService.alert('Invalid Id', 'User has entered an Id that does not currently exist in the database.', 'error', {});
					});
				}
				else {
					// TO BE IMPLEMENTED -- USE SYNCED DATA
				}
			}
			else {
				NotificationService.alert('Invalid Search', 'Inputed value is not a valid Id. Please only input numbers.', 'error', {});
			}
		}

		$scope.ClearQuote = function () {
			$scope.quoteRefNumber = undefined;
			$scope.quote = [];
		}

		$scope.CreateSaleOrder = function () {
			if ($rootScope.onLineFlag == true) {
				if ($scope.quote.OrderId > 0) {
					NotificationService.alert('Lead Already Sold', 'The order already has a sale order associated with it!', 'warning', {});
					$scope.DisableQuoteSold = true;
				}
				else {
					$location.path('/create-sales-order/' + $scope.quote.LeadId);
				}
			}
			else {
				NotificationService.alert('No Connection', 'Could not connect to server. Please Sync with server as soon as possible.', 'warning', {});
			}
		}

		$scope.SaveQuote = function () { //Likelyhood picklist does not currently exist and End caps, Photo Opp and Sold do not do anything in the backend.
			if ($rootScope.onLineFlag == true) {
				var data = {
					LeadDetail: $scope.quote
				};
				$scope.UpdateQuotePromise = LeadProfileService.updateLead(data);
				$scope.UpdateQuotePromise.then(function (output) {
					NotificationService.alert('Update Successful', 'The quotation has been updated and uploaded to the main system.', 'success', {});
				});
			}
			else {
				NotificationService.alert('No Connection', 'Could not connect to server. Please Sync with server as soon as possible.', 'warning', {});
			}
			// TO BE IMPLEMENTED -- UPDATE SYNCED DATA
		}

		//SURVEY CONSOLE
		$scope.SubmitSurveyRef = function (ref) {
			if (Number.isInteger(ref)) {
				if ($rootScope.onLineFlag == true) {
					$scope.SurveyPromise = SearchService.viewOrder(ref);
					$scope.SurveyPromise.then(function (output) {
						$scope.surveyDTO = {
							SaleOrderId: null,
							SurveyDate: null,
							BlindsRemoved: null,
							HelpRequired: null,
							iPadPhotos: null,
							SurveyUploaded: null,
							SpokenToCustomer: null,
							CameraPhotos: null,
							SurveySentToSupplier: null,
							ConservatoryIssue: null,
							SurveyVideoCode: null,
							NoVidReason: null,
							SurveyComments: null,
							OrderLines: null
						}
						$scope.surveyLines = [];
						$scope.surveySummary = [];
						$scope.showSurveyLines = false;
						$scope.survey = output.data.OrderDetail;
						$scope.surveyRefNumber = ref;
						$scope.surveyDTO.SaleOrderId = $scope.survey.OrderId;
						$scope.surveyDTO.SurveyDate = moment();
						$scope.surveyDTO.SurveyComments = $scope.survey.SurveyComments;
						GetExistingSaleOrderLines($scope.RefNum);
					}, function (error) {
						NotificationService.alert('Invalid Id', 'User has entered an Id that does not currently exist in the database.', 'error', {});
					})
				}
				else {
					// TO BE IMPLEMENTED -- USE SYNCED DATA
				}
			}
			else {
				NotificationService.alert('Invalid Search', 'Entered value is not a valid Id. Please only input numbers.', 'error', {});
			}
		}

		function GetExistingSaleOrderLines(id) {
			$scope.gettingExistingLinesPromise = SaleOrderService.getOrderLines($scope.survey.OrderId);
			$scope.gettingExistingLinesPromise.then(function (output) {
				if (output.data.Orderlines)
					$scope.surveyLines = output.data.Orderlines;

				if (output.data.ItemGroups) {
					$scope.surveySummary = output.data.ItemGroups;
				}
			}, function (error) {
				NotificationService.alert('Existing Lines Error', 'There was an error attempting to retrieve the existing order lines.', 'error', {});
			})
		}

		$scope.ClearSurvey = function () {
			$scope.surveyRefNumber = undefined;
			$scope.survey = {};
			$scope.surveyLines = [];
			$scope.surveySummary = [];
		}

		$scope.openFileLogModal = function () {
			var data = {
				id: $scope.survey.OrderId,
				CurrentProfile: $scope.survey.LeadId,
				RequestArea: "LeadProfile"
			};
			openModal('views/modal-templates/file-log.html', 'FileLogController', data);
		};

		$scope.AddFiles = function () {
			openModal('views/modal-templates/add-file-survey.html', 'AddSurveyFile', {
				Id: $scope.survey.OrderId,
				CurrentProfile: $scope.survey.LeadId,
				RequestArea: "LeadProfile"
			});
		};

		$scope.MakeNewInstallationAppointmentSurveyConsole = function () {
			var type = "Installation";
			if (type == 'Installation' && !$scope.survey.SurveyApptDetails)
				NotificationService.alert('Survey Details', 'You must have surveyed the customer before booking an installation appointment.', 'warning', {});
			else {
				var Data = {
					Type: type == 'Installation' ? 'Installation' : 'Survey',
					ObjectID: $scope.survey.SystemId,
					Ref: $scope.survey.LeadId,
					sender: $rootScope.User.FullName(),
					Customer: $scope.survey.FullName,
					SelectedRecipient: $scope.survey.Fitter.Id
				}

				var modalInstance = $modal.open({
					animation: true,
					size: 'lg',
					templateUrl: 'views/modal-templates/book-appointment.html',
					controller: 'CreateAppointmentModalCtrl',
					resolve: {
						Data: function () {
							return Data;
						}
					}
				});

				modalInstance.result.then(function (output) {
					$scope.initHrefCheck();
				});
			}
		};

		function openModal(modalTemplate, Controller, modalData, config) {
			return $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: modalTemplate,
				controller: Controller,
				resolve: {
					data: function () {
						return modalData;
					},
					config: function () {
						return config
					}
				}
			});
		};

		$scope.toggleSurveyLine = function () {
			$scope.showSurveyLines = $scope.showSurveyLines == false ? true : false;
		}

		$scope.addSurveyLine = function (line, index) {
			if (line)
				line.Index = index;

			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'AddNewLineModal.html',
				controller: 'SurveyAddNewLineController',
				resolve: {
					picklist: function () {
						return $scope.picklist;
					},
					line: function () {
						return line || null;
					}
				}
			})

			modalInstance.result.then(function (result) {
				if (result)
					if (result.Updated) {
						$scope.clearSurveyLine(result.Index);
						$scope.surveyLines.splice(result.Index, 0, result);
					} else
						$scope.surveyLines.push(result);
			})
		}

		$scope.clearSurveyLine = function (index) {
			$scope.surveyLines.splice(index, 1);
		}

		$scope.SaveSurvey = function () {
			$scope.surveyDTO.OrderLines = $scope.surveyLines;
			$scope.savingSurveyPromise = SaleOrderService.iPadCreateSurvey($scope.surveyDTO);
			$scope.savingSurveyPromise.then(function (success) {
				NotificationService.alert('Survey Created', 'This survey has been successfully created.', 'success', {});
				$location.path('/sales-order/' + $scope.surveyRefNumber);
			}, function (error) {
				NotificationService.alert('Survey Error', 'There was an error attempting to create this survey.', 'error', {});
			})
		}

		$scope.BackToOrder = function (id) {
			if (confirm("Are you sure you want to discard any changes and proceed to the sale order?"))
				$location.path('/sales-order/' + id);
		};

		//INSTALLATION CONSOLE
		$scope.SubmitInstallRef = function (ref) {
			if (Number.isInteger(ref)) {
				if ($rootScope.onLineFlag == true) {
					$scope.InstallPromise = SearchService.viewOrder(ref);
					$scope.InstallPromise.then(function (output) {
						/*$scope.surveyDTO = {
							SaleOrderId: null,
							SurveyDate: null,
							BlindsRemoved: null,
							HelpRequired: null,
							iPadPhotos: null,
							SurveyUploaded: null,
							SpokenToCustomer: null,
							CameraPhotos: null,
							SurveySentToSupplier: null,
							ConservatoryIssue: null,
							SurveyVideoCode: null,
							NoVidReason: null,
							SurveyComments: null,
							OrderLines: null
						}
						$scope.surveyLines = [];
						$scope.surveySummary = [];
						$scope.showSurveyLines = false;*/

						// Check for status
						if (output.data.OrderDetail.CurrentStatus != "Surveyed") {
							NotificationService.alert('Not Surveyed', 'The install console can only be used with orders currently in the Surveyed status.', 'error', {});
						}
						$scope.install = output.data.OrderDetail;
						$scope.installRefNumber = ref;
						$scope.installRemake = false;
						//$scope.surveyDTO.SaleOrderId = $scope.survey.OrderId;
						//$scope.surveyDTO.SurveyDate = moment();
						//GetExistingSaleOrderLines($scope.RefNum);
					}, function (error) {
						NotificationService.alert('Invalid Id', 'User has entered an Id that does not currently exist in the database.', 'error', {});
					})
				}
				else {
					// TO BE IMPLEMENTED -- USE SYNCED DATA
				}
			}
			else {
				NotificationService.alert('Invalid Search', 'Entered value is not a valid Id. Please only input numbers.', 'error', {});
			}
		}

		function currency(inputValue, inputRoute) {
			if (inputValue == null) {
				return null;
			}
			else {
				if (inputRoute == "Amount") {
					var strCurrency = inputValue.Amount.toString();
				}
				else
					var strCurrency = inputValue.toString();
				if (strCurrency.substring(0, 1) == '+')
					strCurrency = strCurrency.substring(1, strCurrency.length);
				if (strCurrency.substring(0, 1) == '-' && inputRoute != 'Balance')
					NotificationService.alert('Negative Number Detected', 'The number inside ' + inputRoute + ' is negative. Please input a non negative number.', 'error', {});
				else if (strCurrency != '' || strCurrency != 0) {
					if (strCurrency / strCurrency == 1 || strCurrency == 0) {
						if (strCurrency.includes('.')) {
							if (inputValue.length < 3) {
								var cutCurrency = strCurrency;
							}
							else {
								var cutCurrency = strCurrency.slice((strCurrency.length - 3), strCurrency.length);
							}
							if (cutCurrency.substring(0, 1) != '.') {
								if (cutCurrency.substring(1, 2) != '.') {
									if (cutCurrency.substring(2, 3) != '.') {
										NotificationService.alert('Invalid Item', 'Invalid ' + inputRoute + ' detected. Please input a valid amount of currency.', 'error', {});
									}
									else
										strCurrency = strCurrency + '00';
								}
								else if (inputValue.length < 3)
									strCurrency = strCurrency + '00';
								else
									strCurrency = strCurrency + '0';
							}
							else if (inputValue.length < 3)
								strCurrency = '0' + strCurrency + '0';
							else if (strCurrency.length == 3)
								strCurrency = '0' + strCurrency;
						}
						else
							strCurrency = strCurrency + '.00';
					}
					else
						NotificationService.alert('Invalid Item', 'Unexpected values detected inside ' + inputRoute + '. Please input a valid amount of currency.', 'error', {});
				}
				else
					strCurrency = '0.00';


				if (inputRoute == "Order Value")
					$scope.install.OrderValue = strCurrency;
				else if (inputRoute == "Deposit Amount")
					$scope.install.DepositAmount = strCurrency;
				else if (inputRoute == "Balance")
					$scope.install.Balance = strCurrency;
				else if (inputRoute == "Amount") {
					for (var i = 0; i < $scope.orderDetails.Payments.length; i++) {
						if ($scope.install.Payments[i].Id == inputValue.Id)
							$scope.install.Payments[i].Amount = strCurrency;
					}
				}
				return strCurrency;
			}
		}

		$scope.balanceRecalculate = function () { //if OrderValue or DepositAmount are modified, Balance must be changed.
			if ($scope.install.OrderValue == null || $scope.install.DepositAmount == null)
				return null
			else {
				$scope.install.Balance = $scope.install.OrderValue - $scope.install.DepositAmount;
				currency($scope.install.Balance, 'Balance');
			}
		}

		$scope.openCreatePaymentModal = function (OrderId, PaymentId, Type, Payment) {
			var data = {
				OrderId: OrderId,
				PaymentId: PaymentId || 0,
				type: Type,
				Payment: {
					Deposit: $scope.install.DepositAmount,
					Balance: $scope.install.Balance,
					OrderTotal: $scope.install.OrderValue,
					TotalPayments: $scope.install.TotalPayments,
					PartPayement90: $scope.install.PartPayment90,
					PartPayment10: $scope.install.PartPayment10,
					PaymentMethod: $scope.install.PaymentMethod,
					PaymentDate: (Payment != undefined && Payment != null) ? Payment.PaymentDate : null
				},
				PickList: $scope.picklist
			};
			var modalInstance = openModal('views/modal-templates/payment.html', 'CreatePaymentController', data);
			modalInstance.result.then(function () {
				$scope.SubmitInstallRef($scope.installRefNumber);
			});
		}

		$scope.toggleRemakes = function (installRemake) {
			console.debug(installRemake);
			if (installRemake == true) {
				// Open create remake modal
				$scope.openRemakesModal($scope.install.OrderId);
			}
		}

		$scope.initHrefCheck = function () {
			$scope.installRemakeLock = true;
		}

		$scope.AddRemakeFiles = function () {
			openModal('views/modal-templates/add-file-remake.html', 'AddRemakeFile', {
				Id: $scope.install.OrderId,
				CurrentProfile: $scope.install.LeadId,
				RequestArea: "LeadProfile"
			});
		};

		$scope.AddPhotoFiles = function () {
			openModal('views/modal-templates/add-file.html', 'AddFile', {
				Id: $scope.install.OrderId,
				CurrentProfile: $scope.install.LeadId,
				RequestArea: "LeadProfile"
			});
		};

		$scope.openRemakesModal = function (OrderId, RemakeId, ReturnVisit) {
			if (ReturnVisit == "0001-01-01T00:00:00")
				ReturnVisit = 0;
			var data = {
				OrderId: OrderId,
				RemakeId: RemakeId || 0,
				Appointment: ReturnVisit || 0,
				Odrerinfo: {
					CustomerName: $scope.install.Forename + ' ' + $scope.install.Surname,
					Sender: $rootScope.User.FullName(),
					SelectedRecipient: $scope.install.Fitter ? $scope.install.Fitter.Id : null,
				},
				ExternalRef: $scope.installRefNumber
			};
			var modalInstance = openModal('views/modal-templates/remake.html', 'RemakeController', data);
			modalInstance.result.then(function () {
				$scope.initHrefCheck();
			});
		};

		$scope.ClearInstall = function () {
			$scope.installRefNumber = undefined;
			$scope.install = {};
			$scope.installRemake = false;
			$scope.installRemakeLock = false;
		}

		$scope.SaveInstall = function () {

			if ($scope.install.CareBookletLeft != true) {
				NotificationService.alert('Unable to Save', 'You must confirm install photos have been uploaded in order to continue.', 'error', {});
				return false;
			}

			var data = {
				OrderDetail: $scope.install
			};
			$scope.SaveOrderPromise = ($rootScope.onLineFlag) ? SaleOrderService.updateOrder(data) : IpadService.updateSalesOrder(data);


			$scope.SaveOrderPromise.then(function (success) {
				NotificationService.alert('Install Saved', 'This installation has been successfully saved.', 'success', {});
				$location.path('/sales-order/' + $scope.installRefNumber);
			}, function (error) {
				NotificationService.alert('Install Error', 'There was an error attempting to save this installation.', 'error', {});
			})
		}

		// CONTRACT CONSOLE
		$scope.contractConsoleSearch = function () {
			var data = { Filter: $scope.contractConsole.Filter, FitterIds: $scope.contractConsole.Fitters, Start: $scope.contractConsoleDrpStart, End: $scope.contractConsoleDrpEnd };

			$scope.contractMarkers = {};
			$scope.GetContractConsoleAppointmentsPromise = AppointmentService.GetContractConsoleAppointments(data);
			$scope.GetContractConsoleAppointmentsPromise.then((res) => {
				console.log("Result:", res);
				if (res.data.Data) {
					res.data.Data.forEach((item) => {
						if (item.Address.Lat && item.Address.Lng) {
							$scope.contractMarkers[item.Id] = {
								lat: parseFloat(item.Address.Lat),
								lng: parseFloat(item.Address.Lng),
								focus: false,
								message: "<dt>Name: " + item.LeadName + "</dt>"
									+ "<dt>Address : " + item.Address.PrimaryStreet + "</dt>"
									+ "<dt>Town/City : " + item.Address.Town + "</dt>"
									+ "<dt>County : " + item.Address.County + "</dt>"
									+ "<dt>Postcode: " + item.Address.Postcode + "</dt>"
									+ "<dt>Phone Number: " + (item.PhoneNumber || "") + "</dt>"
									+ "<dt>Job: " + item.Job + "</dt>"
									+ "<dt>Job Booked: " + (item.Date || "") + "</dt>"
									+ "<dt>Order: <a href='#/sales-order/" + item.LeadProfileId + "' target='_blank'>" + item.LeadProfileId + "</a>" + "</dt>",
								icon: {
									iconSize: [25, 41],
									iconAnchor: [12, 41],
									popupAnchor: [1, -34],
									iconUrl: getFitterMarkerIcon(item)
								}
							};
						}
					});
				}
				console.log("Markers: ", $scope.contractMarkers);
			}, (err) => {
				console.log("Error:", err);
			});
		};

		function getFitterMarkerIcon(item) {
			switch (item.Job) {
				case "Sold (To Survey)":
					if (item.Fitter == undefined || item.Fitter == "")
						return "assets/img/spare-marker1.png";
					else
						return "assets/img/enquiry-salesorder-marker.png";
				case "Survey":
					return "assets/img/survey-marker.png";
					
				case "Installation":
					return "assets/img/installation-marker.png";
				case "Remakes":
					return "assets/img/remake-marker.png";
				case "Service Call":
					return "assets/img/Service-marker.png";
				case "Charageable Service Call":
					return "assets/img/chargeable-marker.png";
				case "Maintenance Visit":
					return "assets/img/maintenance-marker.png";
				case "Customer Phone Call Request":
					return "assets/img/courtesy-marker.png";
				default:
					return "assets/img/spare-marker3.png";
			}
		}

		//Offline Sync
		$scope.OfflineSync = function () {
			var modalInstance = $modal.open({
				animation: true,
				templateUrl: 'views/modal-templates/offline-sync-conflicts.html',
				controller: 'OfflineSyncConflict',
			});
		}

		$scope.GetPicklistItemName = function (id) {
			if (id)
				return $scope.picklist.find(function (item) { return item.Id == id }).PickListEntry;
		}

		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};

		$scope.checkSurveyDate = function (date) {
			var today = new Date();
			var checkDate = new Date(date);
			if (checkDate > today)
				return false;
			return true;
		}

	}])

	.controller('SurveyAddNewLineController', ["$scope", "$modalInstance", "picklist", "line", function ($scope, $modalInstance, picklist, line) {

		$scope.Picklist = picklist;
		$scope.newLine = line != null ? line : {
			qty: 1
		};

		$scope.add = function () {
			if (line)
				$scope.newLine.Updated = true;
			$modalInstance.close($scope.newLine);
		}

		$scope.close = function () {
			$modalInstance.dismiss();
		}

	}])
